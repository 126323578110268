"use client";
import cn from "@/libs/cn";
import useClientContext from "@/context/ClientContext";
import ButtonShowMore from "@/modules/public/components/ButtonShowMore";
import CardProduct from "./CardProduct";
import Slide from "@/components/Slide";
import { IApiProductsList } from "@/types/interfaces/products";

import styles from "./SlideProducts.module.scss";

interface SlideProducts {
  locale: string;
  title?: string;
  data: IApiProductsList;
  hrefList: string;
  lazy: boolean;
  className?: string;
  classNameSlide?: string;
}

const SlideProducts = (props: SlideProducts) => {
  const { title, data, hrefList, className, classNameSlide } = props;
  const { isMobileSize } = useClientContext();

  return (
    <div className={cn(styles.SlideProducts, className)}>
      {!!title && (
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <ButtonShowMore href={hrefList} isAtFooter={false} />
        </div>
      )}
      <div className={classNameSlide}>
        {isMobileSize ? (
          <div className={styles.mobileList}>
            {data.slice(0, 4).map((product: any, idx: number) => (
              <div key={product.id} className={styles.slideItem}>
                <CardProduct
                  smalu
                  {...product}
                  priority={isMobileSize ? idx < 2 : idx < 5}
                  imageLoading={isMobileSize ? "lazy" : "eager"}
                />
              </div>
            ))}
          </div>
        ) : (
          <Slide
            classNameItem={styles.slideItem}
            autoMargin={false}
            marginItem={0}
            gap={isMobileSize ? 12 : 20}
            offsetOutside={false}
            arrowsOffsetX={10}
            arrowsOffsetY={-48}
            hideWrapBorder
            hideArrows={isMobileSize}
            hideOnHover={false}
            highlightItemOnHover={false}
          >
            {data.map((product: any, idx: number) => (
              <CardProduct
                key={product.id}
                small
                {...product}
                priority={isMobileSize ? idx < 2 : idx < 5}
              />
            ))}
          </Slide>
        )}
      </div>
    </div>
  );
};

export default SlideProducts;
