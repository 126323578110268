"use client";
import routesPublic from "@/modules/public/constants/routesPublic";
import SlideProducts from "./SlideProducts";
import { useDataPublicSalesProducts } from "@/modules/public/hooks/useData";
import useClientContext from "@/context/ClientContext";

interface SlideProductsSalesProps {
    className?: string;
    classNameSlide?: string;
}

const SlideProductsSales = (props: SlideProductsSalesProps) => {
    const { className, classNameSlide } = props;
    const { locale, translate } = useClientContext();

    const sales = useDataPublicSalesProducts();

    return (
        <SlideProducts
            locale={locale}
            className={className}
            classNameSlide={classNameSlide}
            title={translate("Sales")}
            data={sales.data}
            hrefList={routesPublic.promotionSales()}
            lazy={false}
        />
    );
};

export default SlideProductsSales;
